<template>
	<div class="grid p-fluid formgrid grid ">
		<Toast />
        <div class="col-12 lg:col-12">
			<Toolbar class="mb-4">
				<template v-slot:start>
					<div class="my-2">
						<!-- <Button label="Nuevo" icon="pi pi-plus" class="p-button-raised p-button-success mr-2" @click="openNew" /> -->
					</div>
					
					<div class="mt-3 ml-5 flex align-items-center">
						<!-- <center> -->
							<h3>Informe de Detalles</h3>
						<!-- </center> -->
					</div>
				</template>
				<template v-slot:end>
					<!-- <Button label="Historicos" icon="pi pi-history" class="p-button-raised p-button-help mr-2" @click="openHistoricos" /> -->
					<Button label="Exportar" icon="pi pi-file-excel" class="p-button-raised p-button-success mr-2" @click="openFiltersExport" />
				</template>
			</Toolbar>
        </div>
		<div class="col-8 lg:col-8">
			<div class="field col-12 md:col-12 lg:col-12">
				<label for="proveedor"><strong>Empresa de Transporte</strong></label>
				<Dropdown v-model="select_proveedor" :onChange="customer_selected" :showClear="true" ref="select_proveedor" :options="customers_group" optionValue="id_customer" optionLabel="name" placeholder="Seleccione un Campo" />
				
			</div>
		</div>
		<div class="col-2 lg:col-2">
			<Button type="button" class="mt-4 py-3 p-button-raised" label="Buscar" icon="pi pi-search" :loading="loading[0]" @click="load(1)" />
		</div>
		<div class="col-2 lg:col-2">
			<Button type="button" class="mt-4 py-3 p-button-raised" label="Limpiar" icon="pi pi-filter-slash" :loading="loading[0]" @click="limpiar" />
		</div>
		<template v-if="select_proveedor!=null">
						<!-- <div class="col-3 lg:col-3 mt-2">
							<label for="proveedor"><strong>Generador</strong></label>
							<template v-if="generadores.length==0">
								<Dropdown v-model="select_estado" :filter="false" :showClear="true" disabled :filterFields="['nombrecompleto']" :options="generadores" optionLabel="nombrecompleto" optionValue="documento" placeholder="Debes cargar primero los movimientos de transporte de una empresa" />
							</template>
							<template v-else>
								<Dropdown v-model="select_estado" :filter="false" :showClear="true" :filterFields="['nombrecompleto']" :options="generadores" optionLabel="nombrecompleto" optionValue="documento" placeholder="Seleccione un Generador" />
							</template>
						</div> -->
						<div class="col-2 lg:col-2 mt-2">
							<label for="proveedor"><strong>Núm Referencia</strong></label>
							<InputText type="number" v-model="num_referencia" placeholder="Núm Referencia" />
						</div>
						<div class="col-2 lg:col-2 mt-2">
							<label for="proveedor"><strong>Placa</strong></label>
							<InputMask mask="aaa-999" v-model="placa" placeholder="Placa" />
						</div>
						<div class="col-2 lg:col-2 mt-2">
							<label for="proveedor"><strong>Remitente</strong></label>
							<!-- <InputMask mask="aaa-999" v-model="remitente" placeholder="Remitente" /> -->
							<InputText placeholder="Remitente" v-model="remitente"  />
						</div>
						<div class="col-2 lg:col-2 mt-2">
							<label for="proveedor"><strong>Destinatario</strong></label>
							<!-- <InputMask mask="aaa-999" v-model="destinatario" placeholder="Destinatario" /> -->
							<InputText placeholder="Destinatario" v-model="destinatario"  />
						</div>
						<div class="col-2 lg:col-2" v-if="!frmfiltros.rangofechasolicitud">
									<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="ml-3 mr-1 mb-2"></Checkbox>

									<label for="checkrangofechasolicitud">Rango</label>
									<span class="mt-2 md:mt-0 p-input-icon-left">
										<Calendar v-model="text_filter" :showTime="false" :showButtonBar="true"  :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha"/>
									</span>
								</div>
								<div class="col-2 lg:col-2" v-else>
									<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="ml-3 mr-1 mb-2"></Checkbox>
						
									<label for="checkrangofechasolicitud">Rango</label>
									<span class="mt-2 md:mt-0 p-input-icon-left">
										<Calendar v-model="text_filter" :showTime="false" :showButtonBar="true" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha"/>
									</span>
								</div>
								<div class="col-2 lg:col-2 mt-4 pt-1" v-if="frmfiltros.rangofechasolicitud">
									<span class="mt-2 md:mt-0 p-input-icon-left">
										<Calendar v-model="frmfiltros.fechasolicitudrango" :minDate="text_filter" :showButtonBar="true"  :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha final"/>
									</span>
								</div>
					
        <div class="field col-12 mt-3">
				<DataTable ref="pagination" :value="remesas" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" :filters="filters" @paginate="onChangePage"
							:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
							:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
					

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<Column field="fecha" header="Fecha" headerStyle="width: 7rem">
						<template #body="slotProps">
							<span class="p-column-title">fecha</span>
							{{slotProps.data.movimiento_fecha}}
						</template>
					</Column>
					<Column field="numero" header="Núm Ref">
						<template #body="slotProps">
							<span class="p-column-title">núm</span>
							{{slotProps.data.numero}}
						</template>
					</Column>
					<Column field="vehiculo_placa" header="Placa">
						<template #body="slotProps">
							<span class="p-column-title">vehiculo_placa</span>
							{{slotProps.data.vehiculo_placa}}
						</template>
					</Column>
					<!-- <Column field="generador_nombre" header="Generador">
						<template #body="slotProps">
							<span class="p-column-title">generador_nombre</span>
							{{slotProps.data.generador_nombre}}
						</template>
					</Column> -->
					<Column field="movimiento_numero" header="Movimiento">
						<template #body="slotProps">
							<span class="p-column-title">movimiento_numero</span>
							{{slotProps.data.movimiento_numero}}
						</template>
					</Column>
					<Column field="destino" header="Destino">
						<template #body="slotProps">
							<span class="p-column-title">destino</span>
							{{slotProps.data.destino}}
						</template>
					</Column>
					<Column field="remitente" header="Remitente">
						<template #body="slotProps">
							<span class="p-column-title">remitente</span>
							{{slotProps.data.remitente_nombre}}<br>
							{{slotProps.data.origen_descripcion}}
						</template>
					</Column>
					<Column field="destinatario_nombre" header="Destinatario">
						<template #body="slotProps">
							<span class="p-column-title">destinatario_nombre</span>
							{{slotProps.data.destinatario_nombre}}<br>
							{{slotProps.data.destino_descripcion}}
						</template>
					</Column>
					<Column field="estado" header="Estado">
						<template #body="slotProps">
							<span class="p-column-title">estado</span>
							{{slotProps.data.movimiento_estado}}
						</template>
					</Column>
					<Column field="acciones" header="Archivos">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" :loading="loading_reme[slotProps.index]" @click="show_remesa(slotProps.data,slotProps.index)" />
						</template>
					</Column>
				</DataTable>
				<div>
					<Paginator :v-model:first="pagination.from" :rows="pagination.per_page" :rowsPerPageOptions="[15]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
				</div>
				</div>
				</template>

	<Dialog v-model:visible="modalActores" :style="{width: '650px'}" header="Información de los Actores" :modal="true" class="p-fluid">
		<div class="field formgrid grid">
			<div class="field col-9">
				<div class="surface-section">
					<div class="font-medium text-3xl text-900 mb-1 mt-3">Conductor</div>
					<div class="text-500 mb-2">Encuentre aquí la información del conductor.</div>
					<ul class="list-none p-0 m-0">
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-4 font-medium">Nombre</div>
							<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.conductor_nombre}}</div>
						</li>
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-4 font-medium">Cédula</div>
							<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.conductor_documento}}</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="field col-3">
				<Galleria :value="img_conductor" :responsiveOptions="galleriaResponsiveOptions" :numVisible="3" :circular="true" containerStyle="max-width: 150px;max-height: 80px!important; margin: auto">
					<template #item="slotProps">
						<img :src="slotProps.item.url" :alt="slotProps.item.identificacion" style="width: 100%; display: block" />
					</template>
				</Galleria>
			</div>
			<div class="field col-12">
				<template v-if="img_conductor.length>0">
						<div class="font-medium text-3xl text-900 mt-5 mb-1" style="margin-top: 5rem !important;">Propietario</div>
					</template>
					<template v-else>
						<div class="font-medium text-3xl text-900 mt-5 mb-1">Propietario</div>
					</template>
					<div class="text-500 mb-2">Encuentre aquí la información del propietario.</div>
					<ul class="list-none p-0 m-0">
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-4 font-medium">Nombre</div>
							<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.propietario_nombre}}</div>
						</li>
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-4 font-medium">Cédula</div>
							<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.propietario_documento}}</div>
						</li>
					</ul>
					<div class="font-medium text-3xl text-900 mt-5 mb-1">Asesor</div>
					<div class="text-500 mb-2">Encuentre aquí la información del asesor.</div>
					<ul class="list-none p-0 m-0">
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-4 font-medium">Nombre</div>
							<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.asesor_nombre}}</div>
						</li>
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-4 font-medium">Cédula</div>
							<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.asesor_documento}}</div>
						</li>
					</ul>
					<div class="font-medium text-3xl text-900 mt-5 mb-1">Vehiculo</div>
					<div class="text-500 mb-2">Encuentre aquí la información del Vehiculo.</div>
					<ul class="list-none p-0 m-0">
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-4 font-medium">Placa</div>
							<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.vehiculo_placa}}</div>
						</li>
						<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
							<div class="text-500 w-6 md:w-4 font-medium">Clase</div>
							<div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data_fila.movimiento_claseveh}}</div>
						</li>
					</ul>
					<Galleria :value="img_vehiculo" :responsiveOptions="galleriaResponsiveOptions" class="mt-5" :numVisible="3" :circular="true" containerStyle="max-width: 230px;max-height: 80px; margin: auto">
						<template #item="slotProps">
							<img :src="slotProps.item.url" :alt="slotProps.item.identificacion" style="width: 100%; display: block" />
						</template>
						<template #thumbnail="slotProps">
							<img :src="slotProps.item.url" :alt="slotProps.item.identificacion"  style="width: 100%; display: block" />
						</template>
					</Galleria>
				<!-- </div> -->
			</div>
		</div>
		<template #footer>
			<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalActores = false"/>
		</template>
	</Dialog>
	<Dialog v-model:visible="modalDetalle" :style="{width: '900px'}" :header="'Detalle movimiento #'+movimiento_numero" :modal="true" class="p-fluid">
		<div class="field formgrid grid">
			<div class="field col-12">
				<Accordion>
				<!-- <Accordion :activeIndex="0"> -->
					<template v-for="(detalle_mov,index) in info_detalle_mov" :key="index">
					<AccordionTab>
						<template v-slot:header>
							<div class="flex ">
								<i class="pi pi-map-marker fs-xlarge mr-3 pink-color my-5"></i>
									<div class="field formgrid grid p-fluid">
										<div class="field col-4">
											<p><strong>Origen: </strong><br>{{detalle_mov.remitente_nombre}}<br>
											{{detalle_mov.origen_descripcion}}</p>
										</div>
										<div class="field col-4">
											<p><strong>Destino: </strong><br>{{detalle_mov.destinatario_nombre}}<br>
											{{detalle_mov.destino_descripcion}}</p>
										</div>
										<div class="field col-4">
											<p><strong>Carga: </strong><br>{{detalle_mov.producto_descripcion}}<br>
											{{new Intl.NumberFormat().format(detalle_mov.peso_final)}}Kg</p>
										</div>
									</div>
							</div>
						</template>
						<div class="field formgrid grid p-fluid">
							
							<div class="field col-6 shadow-1 rounded-normal p-3">
								<h5>Remitente</h5>
								<p>NIT: #{{detalle_mov.remitente_documento}}</p>
								<p>{{detalle_mov.remitente_nombre}}</p>
								<p>{{detalle_mov.remitente_direccion}}<br>{{detalle_mov.remitente_zona}}</p>
								<p>{{detalle_mov.remitente_telefono}}</p>
							</div>
							<div class="field col-6 shadow-1 rounded-normal p-3">
								<h5>Destinatario</h5>
								<p>NIT: #{{detalle_mov.destinatario_documento}}</p>
								<p>{{detalle_mov.destinatario_nombre}}</p>
								<p>{{detalle_mov.destinatario_direccion}}<br>{{detalle_mov.destinatario_zona}}</p>
								<p>{{detalle_mov.destinatario_telefono}}</p>
							</div>

							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Origen</h5>
								<p>{{detalle_mov.origen_descripcion+' - '+detalle_mov.origen_detalle}}</p>
							</div>
							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Destino</h5>
								<p>{{detalle_mov.destino_descripcion+' - '+detalle_mov.destino_detalle}}</p>
							</div>
							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Valor</h5>
								<p>{{'$'+new Intl.NumberFormat().format(detalle_mov.valor_declarado)}}</p>
							</div>

							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Producto</h5>
								<p>{{detalle_mov.producto_descripcion}}</p>
								<p>{{detalle_mov.empaque_descripcion}}</p>
							</div>
							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Peso</h5>
								<p><strong>Inicial: </strong>{{detalle_mov.peso_inicial}}</p>
								<p><strong>Final: </strong>{{detalle_mov.peso_final}}</p>
							</div>
							<div class="field col-4 shadow-1 rounded-normal p-3">
								<h5>Unidades</h5>
								<p><strong>Inicial: </strong>{{detalle_mov.unidades_inicial}}</p>
								<p><strong>Final: </strong>{{detalle_mov.unidades_final}}</p>
							</div>
						</div>

					</AccordionTab>
					</template>
				</Accordion>


			</div>
		</div>
		<template #footer>
			<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalDetalle = false"/>
		</template>
	</Dialog>
	<Dialog v-model:visible="modaldocumentos" :style="{width: '900px'}" :modal="true" class="p-fluid">
	<!-- <Dialog v-model:visible="modaldocumentos" :style="{width: '900px'}" :header="'Documentos movimiento #'+movimiento_numero" :modal="true" class="p-fluid"> -->
		<template v-slot:header>
			<template  v-if="estados_mov.accepted">
				<div class="field formgrid grid  text-center flex">
					<template v-if="estados_mov.accepted == 41">
					<div class="field col-3 p-2">
						<p>Documentos movimiento <strong>#{{movimiento_numero}}</strong></p>
					</div>
					</template>
					<template v-else>
					<div class="field col-4 p-2">
						<p>Documentos movimiento <strong>#{{movimiento_numero}}</strong></p>
					</div>
					</template>	
						
					<template v-if="estados_mov.accepted == 41">
					<div class="field col-3 p-2 pt-3 card_header_doc_mov">
						<template v-if="estados_mov.accepted == 41">
							<i class="pi pi-user pink-color" style="font-size: 1.5rem"></i>
						</template>
						<template v-else>
							<template v-if="estados_mov.accepted == 40">
								<i class="pi pi-exclamation-triangle pink-color" style="font-size: 1.5rem"></i>
							</template>
							<template v-else>
								<i class="pi pi-ban pink-color" style="font-size: 1.5rem"></i>
							</template>
						</template>
						<strong>
						<template v-if="estados_mov.accepted == 40">
							<p>Pendiente por aceptar el Conductor</p>
						</template>
						<template v-if="estados_mov.accepted == 41">
							<p>Aceptado por el Conductor</p>
						</template>
						<template v-if="estados_mov.accepted == 42">
							<p>Rechazado por el Conductor</p>
						</template>
						<template v-if="estados_mov.accepted == 43">
							<p>Rechazado por Systram</p>
						</template>
						<template v-if="estados_mov.accepted == 48">
							<p>Rechazado por Seway</p>
						</template>
						</strong>
					</div>
					</template>
					<template v-else>
					<div class="field col-4 p-2 pt-3 card_header_doc_mov">
						<template v-if="estados_mov.accepted == 41">
							<i class="pi pi-user pink-color" style="font-size: 1.5rem"></i>
						</template>
						<template v-else>
							<template v-if="estados_mov.accepted == 40">
								<i class="pi pi-exclamation-triangle pink-color" style="font-size: 1.5rem"></i>
							</template>
							<template v-else>
								<i class="pi pi-ban pink-color" style="font-size: 1.5rem"></i>
							</template>
						</template>
						<strong>
						<template v-if="estados_mov.accepted == 40">
							<p>Pendiente por aceptar el Conductor</p>
						</template>
						<template v-if="estados_mov.accepted == 41">
							<p>Aceptado por el Conductor</p>
						</template>
						<template v-if="estados_mov.accepted == 42">
							<p>Rechazado por el Conductor</p>
						</template>
						<template v-if="estados_mov.accepted == 43">
							<p>Rechazado por Systram</p>
						</template>
						<template v-if="estados_mov.accepted == 48">
							<p>Rechazado por Seway</p>
						</template>
						</strong>
					</div>
					</template>	
						
					<template v-if="estados_mov.accepted == 41">
					<div class="field col-3 p-2 pt-3 card_header_doc_mov">
						<i class="pi pi-box pink-color" style="font-size: 1.5rem"></i>
						<strong>
						<template v-if="estados_mov.delivery == 43">
							<p>Pendiente por entregar</p>
						</template>
						<template v-if="estados_mov.delivery == 44">
							<p>Finalizadas todas las entregas</p>
						</template>
						</strong>
					</div>
					</template>
					<template v-else>
					<div class="field col-4 p-2 pt-3 card_header_doc_mov">
						<i class="pi pi-box pink-color" style="font-size: 1.5rem"></i>
						<strong>
						<template v-if="estados_mov.delivery == 43">
							<p>Pendiente por entregar</p>
						</template>
						<template v-if="estados_mov.delivery == 44">
							<p>Finalizadas todas las entregas</p>
						</template>
						</strong>
					</div>
					</template>	
						
					<template v-if="estados_mov.accepted == 41">
					<div class="field col-3 p-2 card_header_doc_mov">
						<strong>
						
							<template v-if="estados_mov.status == 45">
								<i class="pi pi-clock pink-color" style="font-size: 1.5rem"></i>
								<p>Pendiente por iniciar entregas</p>
							</template>
							<template v-if="estados_mov.status == 46">
								<i class="pi pi-directions pink-color" style="font-size: 1.5rem"></i>
								<p>En transito</p>
							</template>
							<template v-if="estados_mov.status == 47">
								<i class="pi pi-check-circle pink-color" style="font-size: 1.5rem"></i>
								<p>Entregas verificadas</p>
							</template>
						
						</strong>
					</div>
					</template>
				</div>
			</template>
			<template  v-if="!estados_mov.accepted">
				<div class="field formgrid grid  text-center flex">
					<div class="field col-12">
						<p>Documentos movimiento <strong class="">#{{movimiento_numero}}</strong></p>
					</div>
				</div>
			</template>
		</template>
		<div class="field formgrid grid card">
			<div class="field col-12">
				<h4>Manifiesto</h4>
			</div>
			<div class="field col-3 text-center">
				<p><strong>Origen:<br> </strong>{{info_docs_movimientos.origen}}</p>
				<p><strong>Destino:<br> </strong>{{info_docs_movimientos.destino}}</p>
			</div>
			<div class="field col-3 text-center py-4">
				<p><strong>Numero de Manifiesto:<br> </strong>{{info_docs_movimientos.numero}}</p>
			</div>
			<div class="field col-3 text-center py-4">
				<p><strong>Sucursal:<br> </strong>{{info_docs_movimientos.manifiesto_sucursal}}</p>
			</div>
			<div class="field col-3 text-center">
				<template v-if="documento_movimiento">
					<a :href="documento_movimiento" target="_blank" class="text-center pink-color"><i class="pi pi-file-pdf pink-color mt-5 mb-1" style="font-size: 3rem"></i><br><strong>Ver Documento</strong></a>
				</template>
				<template v-else>
					<a :href="documento_movimiento" target="_blank" class="text-center gray-color"><i class="pi pi-file-pdf gray-color mt-5 mb-1" style="font-size: 3rem"></i><br><strong>No hay Documento</strong></a>
				</template>
			</div>
		</div>
		<div class="field formgrid grid card">
			<div class="field col-12">
				<h4>Remesas</h4>
			</div>
			<div class="field col-12">
				<DataTable ref="pagination" :value="remesas" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" :filters="filters" @paginate="onChangePage"
							:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
							:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
					

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<Column field="numero" header="Número">
						<template #body="slotProps">
							<span class="p-column-title">numero</span>
							{{slotProps.data.numero}}
						</template>
					</Column>
					<Column field="estado" header="Estado">
						<template #body="slotProps">
							<span class="p-column-title">estado</span>
							{{slotProps.data.estado}}
						</template>
					</Column>
					<Column field="sucursal" header="Sucursal">
						<template #body="slotProps">
							<span class="p-column-title">sucursal</span>
							{{slotProps.data.sucursal}}
						</template>
					</Column>
					<Column field="fecha" header="Fecha">
						<template #body="slotProps">
							<span class="p-column-title">fecha</span>
							{{slotProps.data.fecha}}
						</template>
					</Column>
					<Column field="hora" header="Hora">
						<template #body="slotProps">
							<span class="p-column-title">hora</span>
							{{slotProps.data.hora}}
						</template>
					</Column>
					<Column field="usuario" header="Usuario">
						<template #body="slotProps">
							<span class="p-column-title">usuario</span>
							{{slotProps.data.usuario}}
						</template>
					</Column>
					<Column field="acciones" header="Acciones">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success mr-2" :loading="loading_reme[slotProps.index]" @click="show_remesa(slotProps.data,slotProps.index)" />
						</template>
					</Column>
				</DataTable>
				</div>
			
		</div>
		<template #footer>
			<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modaldocumentos = false"/>
		</template>
	</Dialog>
	<Dialog v-model:visible="modaldetalleremesas" :style="{width: '1000px'}" :header="'Documentos movimiento #'+movimiento_numero" :modal="true" class="p-fluid">
		<div class="field formgrid grid flex py-6">
			<div class="field col-12 align-items-center" style=" padding: 0px 8rem;">
				<!-- <p>{{details_remesa}}</p> -->
				<!-- <template v-for="(remesa,index) in details_remesa" :key="index">
					<p>{{remesa}}</p><br>
				</template> -->
				<Timeline :value="details_remesa" layout="horizontal" align="top" class="customized-timeline">
					<template #marker="">
						<span class="custom-marker">
							<!-- <i class="pi pi-shopping-cart"></i> -->
							<i class="pi pi-check-circle pink-color" style="font-size: 3rem"></i>
						</span>
					</template>
					<template #content="">
					</template>
				</Timeline>
			</div>
			<div class="field col-12 text-center">
				<template v-if="code_seway==1">
					<h4>No se encuentran eventos en seway</h4>
				</template>
				<template v-if="code_seway==0">
					<h4>Eventos no disponibles</h4>
				</template>
			<div style="margin-top: 1em">
				<transition-group name="dynamic-box" tag="div" class="grid">
					<div v-for="(col,index) in details_remesa" :key="index" class="col">
						<div class="box">
						<!-- <p>{{col}}</p> -->
						<p class="step_mov"><strong>
								<template v-if="col.id_events==2">
									Llegada de Carga
								</template>
								<template v-if="col.id_events==3">
									Inicio de Carga
								</template>
								<template v-if="col.id_events==4">
									Fin de Carga
								</template>
								<template v-if="col.id_events==5">
									Carga aceptada
								</template>
								<template v-if="col.id_events==6">
									Llegada a la descarga - Remolque enganchado
								</template>
								<template v-if="col.id_events==7">
									Inicio Descargue
								</template>
								<template v-if="col.id_events==8">
									Fin Descargue
								</template>
								<template v-if="col.id_events==9">
									Descarga Aceptada
								</template>
								<template v-if="col.id_events==10">
									Entrega Pendiente
								</template>
								<template v-if="col.id_events==11">
									Entrega Exitosa
								</template>
								<template v-if="col.id_events==12">
									Entrega Rechazada
								</template>
								<template v-if="col.id_events==13">
									Planta de carga reservada
								</template>
								<template v-if="col.id_events==14">
									Inicio de carga en planta
								</template>
								<template v-if="col.id_events==15">
									Fin de carga en planta
								</template>
								<template v-if="col.id_events==16">
									Inicio de descarga en planta
								</template>
								<template v-if="col.id_events==17">
									Fin de descarga en planta
								</template>
								<template v-if="col.id_events==18">
									Descargada en planta no reservada
								</template>
							</strong></p>
							<p>{{col.created_at}}</p>
							<template v-if="col.latitude && col.longitude">
							<a :href="'https://www.google.com.co/maps?q='+col.latitude+','+col.longitude" target="_blank">Ver en Mapa</a>
							</template>
							<br>
							<template v-if="col.image_event.length > 0">
								<Button icon="pi pi-eye" @click="show_galeria_evnets = true" class="p-button-rounded p-button-primary mt-2"/>
								<!-- <div class="flex justify-content-center pb-8 pt-1"> -->
									<Galleria :value="col.image_event" :responsiveOptions="galleriaResponsiveOptions" :numVisible="3" containerStyle="max-width: 50%"
										:circular="true" :fullScreen="true" :showItemNavigators="true" v-model:visible="show_galeria_evnets">
										<template #item="slotProps">
											<img :src="slotProps.item.image_url" :alt="slotProps.item.id" style="width: 60%; display: block;" />
										</template>
									</Galleria>
								<!-- <Image :src="col.url_image" alt="Image" width="150" preview /> -->
								 
								<!-- <Galleria v-model:visible="show_galeria_evnets" :value="col.image_event" :responsiveOptions="galleriaResponsiveOptions" :numVisible="3" :circular="true" containerStyle="max-width: 100px;max-height: 50px!important; margin: auto">
									<template #item="slotProps">
										<img :src="slotProps.item.image_url" :alt="slotProps.item.id" style="width: 100%; display: block" />
									</template>
								</Galleria> -->
								<!-- </div> -->
							</template>

							
						</div>
					</div>
				</transition-group>
			</div>
			<!-- <template v-for="(remesa,index) in details_remesa" :key="index">
				<template v-if="details_remesa.length == 3">
				<div class="field col-4">
					<p>{{remesa}}</p><br>
				</div>
			</template> -->
			</div>
		</div>
		<template #footer>
			<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modaldetalleremesas = false"/>
		</template>
	</Dialog>
	</div>
</template>

<script>
// import axios from 'axios'
export default {
	data() {
		return {
			message: [],
			customers: [],
			customers_group: [],
			details_remesa: [],
			select_proveedor: null,
			documento_movimiento: '',
			events2: [
                "2020", "2021", "2022", "2023"
            ],
            detalle_movimientos: [],
			remesas: [],
            info_docs_movimientos: [],
			generadores: [],
			estados_mov: [],
			columns: [0, 1, 2, 3, 4, 5],
			username:null,
			loader: false,
			movimiento_numero: '',
			modalActores: false,
			code_seway: '',
			modalDetalle: false,
			modaldetalleremesas: false,
			modaldocumentos: false,
			email:null,
			campo_selected: null,
			select_estado: null,
			selectedProducts: null,
			loading: [false, false, false],
			loading_reme: [false, false, false],
			count: 0,
			data_fila: [],
			img_conductor: [],
			img_vehiculo: [],
			info_detalle_mov: [],
			placa: '',
			num_referencia: '',
			remitente: '',
			destinatario: '',
			text_filter: '',
			galleriaResponsiveOptions: [
                {
                    breakpoint: "724px",
                    numVisible: 5,
                },
                {
                    breakpoint: "660px",
                    numVisible: 4,
                },
                {
                    breakpoint: "168px",
                    numVisible: 3,
                },
                {
                    breakpoint: "160px",
                    numVisible: 1,
                },
            ],
            pagination: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			campos_tabla: [
				{name: 'Fecha', code: 'fecha'},
				{name: 'Placa', code: 'placa'},
				{name: 'Generador', code: 'estado'}
			],
			frmfiltros: {
                fechasolicitud: '',
                rangofechasolicitud: false,
                fechasolicitudrango: '',
                cliente: '',
                direccion: '',
                mensajero: '',
                estadopedido: 0
            },
			ingresoCostumer: false
		}
	},
	mounted(){
		// this.cargar_empresas();
        this.loadDataTable1(1);
	},
	methods:{
        loadDataTable1(page){
			// this.cleartable();
            const cookieName = 'Email';
			const IdRol = 'IdRol';
			this.loader = true;
			this.loading[0] = true;
			
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})

			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			var _that = this;
			this.customers_group = [];
                window.api.call('post', '/cargar_customers?&page='+page, {email: email,id_rol: id_rol}).then( function(response) {
                    if(response.status == 200){


						// if (response.data.data.length == 1) {
						// 	_that.customers_group = response.data.data;
						// 	// _that.select_proveedor = response.data.data[0].id_customer;
						// 	_that.select_proveedor = response.data.data[0];
						// 	_that.load(1);
						// }else{
						// 	_that.loader = false;
						// 	_that.customers_group = response.data.data;
						// 	_that.loading[0] = false;
						// }
						_that.customers_group = response.data.data;
						_that.loading[0] = false;
						console.log(_that.customers_group.length);
						// if (_that.customers_group != null) {
							if (_that.customers_group.length == 1) {
								_that.select_proveedor = response.data.data[0].id_customer;
								_that.load(1);
						// 		console.log(_that.select_proveedor);
							}
						// }else{
						// 	console.log(_that.select_proveedor);
						// }
						
                    }
                }).catch(function (err) {
                    console.log(err);
					_that.loader = false;
					_that.loading[0] = false;
                });
		},
		openNew(){

		},
		addColumn() {
				this.columns = [...this.columns, this.columns.length];
			},
			removeColumn() {
				this.columns.pop();
			},
		openFiltersExport(){
			// this.modalFiltros = true;
		},
		customer_selected(){
			console.log(this.select_proveedor);
			if (this.select_proveedor!=null) {
				this.load(1);
				// this.load_indicadores = true;
			}else{

			}
		},
		openactores(data,slot){
			// this.data_fila = data;
			// console.log(data);
			// console.log(slot.index);
			this.loading[slot.index] = true;
			this.data_fila = [];
			this.img_vehiculo = [];
			this.img_conductor = [];
			var _that = this;
			var url = 'info_actores';
			window.api.call('post', url, {placa: data.vehiculo_placa,conductor_documento: data.conductor_documento}).then( function(response) {
				if(response.status == 200){
					_that.data_fila = data;
					_that.loading[slot.index] = false;
					_that.img_vehiculo = response.data.img_vehiculo;
					_that.img_conductor = response.data.img_conductor;
					_that.modalActores = true;
				}
			}).catch(function (err) {
				_that.loading[0] = false;
				_that.loading[slot.index] = false;
				console.log(err);
			});
		},
		limpiar(){
			this.placa = null;
			this.text_filter = null;
			this.num_referencia = '';
			this.frmfiltros.rangofechasolicitud = '';
			this.frmfiltros.fechasolicitudrango = '';
			this.remitente = '';
			this.destinatario = '';
			this.load(1);

		},
		opendocumentos(data,slot){
			// console.log(data);
			// console.log(slot.index);
			this.loading[slot.index] = true;
			this.data_fila = [];
			this.remesas = [];
			this.estados_mov = [];
			this.info_docs_movimientos = [];
			// this.img_conductor = [];
			this.movimiento_numero = data.movimiento_numero;
			var _that = this;
			var url = 'documentos_mov_transporte';
			window.api.call('post', url, {id_customer: this.select_proveedor,movimiento_id: data.movimiento_id}).then( function(response) {
				if(response.status == 200){
					_that.data_fila = data;
					_that.loading[slot.index] = false;
					_that.modaldocumentos = true;
					if (response.data.manifiesto.length > 0) {
						_that.documento_movimiento = response.data.manifiesto[0].url_documento_s3;
						_that.info_docs_movimientos = response.data.manifiesto[0];
					}
					_that.remesas = response.data.remesas;
					if (response.data.estados.length > 0) {
						_that.estados_mov = response.data.estados[0];
					}
					
					
				}
			}).catch(function (err) {
				_that.loading[0] = false;
				_that.loading[slot.index] = false;
				console.log(err);
			});
		},
		verDetalle(data,slot){
			this.info_detalle_mov = [];
			this.loading[slot.index] = true;
			// console.log(data);
			this.movimiento_numero = data.movimiento_numero;
			var _that = this;
			var url = 'detalle_mov_transporte';
			window.api.call('post', url, {id_customer: this.select_proveedor,movimiento_id: data.movimiento_id}).then( function(response) {
				if(response.status == 200){
					// console.log(response.data.data);
					_that.info_detalle_mov = response.data.data;
					_that.modalDetalle = true;
					_that.loading[slot.index] = false;
				}
			}).catch(function (err) {
				_that.loading[0] = false;
				console.log(err);
				_that.loading[slot.index] = false;
			});
		},
		show_remesa(data,index){
			this.details_remesa = [];
			this.code_seway = '';
			this.loading_reme[index] = true;
			var _that = this;
			var url = 'show_remesas_cumplidos';
			window.api.call('post', url, {id_customer: this.select_proveedor,id_detalle: data.id}).then( function(response) {
				if(response.status == 200){
					_that.modaldetalleremesas = true;
					_that.details_remesa = response.data.data;
					_that.loading_reme[index] = false;
					_that.code_seway = response.data.code;
				}
			}).catch(function (err) {
				_that.loading[0] = false;
				console.log(err);
				_that.loading_reme[index] = false;
			});
		},
        onChangePage(page) {
				if(this.search_paginador == false){
					this.loadDataTable1((page.page+1));
				}else{
					this.load(page.page+1);
				}
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
		load(page){
			if (this.select_proveedor==null) {
				this.$toast.add({severity:'error', summary: 'Selecciona una Empresa de transporte', detail:'', life: 4000});
			}else{
				if(this.text_filter && !this.frmfiltros.fechasolicitudrango && this.frmfiltros.rangofechasolicitud == true){
					this.$toast.add({severity:'error', summary: 'Rango incorrecto', detail:'Ingresa fecha inicial y fecha final', life: 4000});
					return;
				}
				this.loading[0] = true;
				var _that = this;
				this.remesas = [];
				if (this.placa) {
					this.placa = this.placa.replaceAll('-', '');
				}
				
				this.generadores = [];
				var url = 'cargar_info_detalle?&page='+page;
				window.api.call('post', url, {id_customer: this.select_proveedor,nit_generador: this.select_proveedor.documento,num_referencia: this.num_referencia,remitente: this.remitente,destinatario: this.destinatario,placa: this.placa,fecha: this.text_filter,fecha_fin: this.frmfiltros.fechasolicitudrango,rango: this.frmfiltros.rangofechasolicitud}).then( function(response) {
					if(response.status == 200){
						_that.remesas = response.data.data.data;
						// _that.remesas = response.data.data;
						
						// _that.generadores = response.data.generadores;
						_that.pagination = response.data.data;
						_that.loading[0] = false;
					}
				}).catch(function (err) {
					_that.loading[0] = false;
					console.log(err);
				});
			}
		}
	}
}
</script>

<style scoped>
.card_header_doc_mov{
	border-radius: 15px;
}
.card_header_doc_mov:hover{
   transition: .5s;
   -webkit-box-shadow: 0px 0px 37px -2px rgba(232,30,101,1);
-moz-box-shadow: 0px 0px 37px -2px rgba(232,30,101,1);
box-shadow: 0px 0px 37px -2px rgba(232,30,101,1);
}
</style>
